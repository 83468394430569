/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ListsWidget10,
} from '../../../_metronic/partials/widgets'
import { ChartsWidget3 } from '../../../_metronic/partials/widgets'
import { getActiveRental, getActiveRentalWithPagination } from '../../modules/subscriptions/core/_requests'
import { ChartsWidget_transactions } from '../../../_metronic/partials/widgets/charts/ChartsWidget_transactions'
import { getAllTransactionLists } from '../../modules/transactions/transaction-list/core/_requests'
import moment from 'moment';
import { useNavigate } from 'react-router-dom'

const DashboardPage: FC<{ activeRental: any, activeAccounts: any, recentTransactions: any, activeAccountRedirect: any, activeRentalRedirect: any, isLoading: any }> = ({ activeRental, activeAccounts, recentTransactions, activeAccountRedirect, activeRentalRedirect, isLoading }) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Rentals'
          activeRenCount={activeRental}
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          activeRentalRedirect={activeRentalRedirect}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Accounts'
          icon={false}
          stats={activeAccounts}
          labelColor='dark'
          textColor='gray-300'
          activeAccountRedirect={activeAccountRedirect}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' /> */}
        <ListsWidget26 className='h-lg-50' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6'>
        {/* <ChartsWidget3 className='card-xl-stretch mb-xl-8 h-md-100' /> */}
        <ChartsWidget_transactions
          transactions={recentTransactions}
          isLoading={isLoading}
          className='card-xl-stretch mb-xl-8 h-md-100'
        />

        {/* <EngageWidget10 className='h-md-100' /> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        {/* <ListsWidget10 className='card-xl-stretch mb-5 mb-xl-8' items={2} /> */}
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'> */}
    {/* <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} /> */}
    {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
    {/* </div>
    </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)

const DashboardWrapper: FC = () => {

  const intl = useIntl();
  const navigate = useNavigate();

  const [activeRental, setActiveRental] = useState<any>()
  const [activeAccounts, setActiveAccounts] = useState<any>()
  const [recentTransactions, setRecentTransactions] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()

  useEffect(() => {
    getActiveRentalWithPagination(0, `search=${""}`).then((res: any) => {
      setActiveRental(res?.data?.length)
      const organizations = res.data?.map((doc: any) => doc?.organization?.organization_name);
      const uniqueOrganizations = new Set(organizations);
      const uniqueOrganizationCount = uniqueOrganizations.size;
      setActiveAccounts(uniqueOrganizationCount);
    })
    fetchData();
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    const { data } = await getAllTransactionLists("") as any;
    setIsLoading(false)

    const approvedTransactions = data.filter((item: any) => item.ResponseText === "Approved");

    const sortedTransactions = approvedTransactions.sort((a: any, b: any) => {
      return moment(b.ProcessedDateTime).diff(moment(a.ProcessedDateTime));
    });

    const mostRecentDate = moment(sortedTransactions[0]?.ProcessedDateTime);
    // console.log(mostRecentDate);

    // Calculate 30 days from the most recent transaction date
    const last30Days = mostRecentDate.subtract(30, 'days');
    // console.log("Filtering Transactions From:", last30Days.format());

    // Filter transactions within the last 30 days of the most recent transaction
    const filteredTransactions = sortedTransactions.filter((transaction: any) => {
      const processedDate = moment(transaction.ProcessedDateTime);
      return processedDate.isAfter(last30Days);
    });

    // console.log("Filtered Transactions (Last 30 Days):", filteredTransactions);

    const aggregatedData: { [date: string]: { amount: number, count: number } } = filteredTransactions.reduce((acc: any, transaction: any) => {
      const date = moment(transaction.ProcessedDateTime).format('YYYY-MM-DD');
      const amount = transaction.Amount / 100;

      if (!acc[date]) {
        acc[date] = { amount: 0, count: 0 };
      }

      acc[date].amount += amount;
      acc[date].count += 1;
      return acc;
    }, {});

    const chartData = Object.entries(aggregatedData).map(([date, { amount, count }]) => ({
      date,
      amount,
      count,
    }));
    setRecentTransactions(chartData);
  };

  const activeAccountRedirect = () => {
    navigate("/active/accounts")
  }

  const activeRentalRedirect = () => {
    navigate("/subscriptions/active")
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage isLoading={isLoading} activeRental={activeRental} activeAccounts={activeAccounts} recentTransactions={recentTransactions} activeAccountRedirect={activeAccountRedirect} activeRentalRedirect={activeRentalRedirect} />
    </>
  )
}

export { DashboardWrapper }
