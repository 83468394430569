import { useState, useEffect } from 'react'
import { Packages } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
import { getDiscounts } from '../../../../../app/modules/rate-discount/discount-list/core/_requests'
interface IMyProps {
  packages: Packages
  handleSelectPackage: (updatedPkg: object) => void
}

interface IDiscount {
  _id: string
  title: string
  amount: number
  descriptions: string
  discount_percent: number
}

const Step4: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props

  const [availableDiscounts, setAvailableDiscounts] = useState<IDiscount[]>([])
  const [selectedDiscount, setSelectedDiscount] = useState<IDiscount | null>(null)

  // Fetch the available discounts
  useEffect(() => {
    const fetchDiscounts = async () => {
      const result = await getDiscounts(`page=0`) as any
      const fetchedDiscounts = result?.data
      // Filter only the discounts with status = true
      const activeDiscounts = fetchedDiscounts?.filter((discount: any) => discount.status === true)

      setAvailableDiscounts(activeDiscounts)
    }
    fetchDiscounts()
  }, [])

  // Auto-select the discount if already present in the newPackage when in edit mode
  useEffect(() => {
    if (newPackage?.weekly_discount) {
      const existingDiscount = availableDiscounts.find(
        (discount) => discount.discount_percent === newPackage.weekly_discount
      )
      if (existingDiscount) {
        setSelectedDiscount(existingDiscount)
        // Remove the selected discount from available discounts to prevent re-selection
        setAvailableDiscounts(availableDiscounts.filter((discount) => discount._id !== existingDiscount._id))
      }
    }
  }, [newPackage, availableDiscounts])

  const handleDiscountSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDiscount = availableDiscounts.find(
      (discount) => discount._id === event.target.value
    )
    if (selectedDiscount) {
      setSelectedDiscount(selectedDiscount)
      setAvailableDiscounts(
        availableDiscounts.filter((discount) => discount._id !== selectedDiscount._id)
      )
      // Update the newPackage with the selected discount
      handleSelectPackage({ ...newPackage, weekly_discount: selectedDiscount.discount_percent })
    }
  }

  const handleRemoveDiscount = () => {
    if (selectedDiscount) {
      setAvailableDiscounts([...availableDiscounts, selectedDiscount])
      setSelectedDiscount(null)
      // Remove the weekly_discount from newPackage
      handleSelectPackage({ ...newPackage, weekly_discount: null })
    }
  }

  return (
    <>
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='fv-row mb-4'>
            <label className='fs-5 fw-semibold mb-2'>Select and add discounts</label>
            <select
              className='form-select form-select-lg'
              onChange={handleDiscountSelect}
              disabled={!!selectedDiscount}  // Disable if a discount is selected
            >
              <option value=''>Select a discount</option>
              {availableDiscounts.map((discount) => (
                <option key={discount._id} value={discount._id}>
                  {discount.title} - {discount.discount_percent}%
                </option>
              ))}
            </select>
          </div>

          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span>Selected Discount</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selected Discount'
              ></i>
            </label>

            {selectedDiscount && (
              <div className='py-5'>
                <div className='mh-300px scroll-y me-n5 pe-5'>
                  <div
                    className='d-flex align-items-center justify-content-between p-3 rounded-3 border border-success mb-1'
                  >
                    <div className='fw-semibold'>
                      <span className='fs-4 fw-bold text-info me-2'>{selectedDiscount.title}</span>
                      <span className='fs-5 text-success'>
                        ({selectedDiscount.discount_percent}%)
                      </span>
                      <p className='text-muted mt-2'>{selectedDiscount.descriptions}</p>
                    </div>
                    <button
                      className='btn btn-icon btn-sm btn-light-danger ms-3'
                      onClick={handleRemoveDiscount}
                    >
                      <i className='bi bi-x'></i> {/* Bootstrap icon for cross */}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { Step4 }
