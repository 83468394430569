import { FC } from 'react';
import { AmountFormatter } from '../../../../../../_metronic/helpers/UtilityHelpers';

type Props = {
  values?: {
    weekly_rate: string;
  };
};

const SubscriptionsInActiveWeeklyRateCell: FC<Props> = ({ values }) => {

  if (!values) return null;

  const { weekly_rate } = values;

  return (
    <div className="text-gray-800">
      <span className='text-gray-800 text-hover-primary mb-1 cursor-pointer'>
        {weekly_rate ? AmountFormatter(parseInt(weekly_rate)) : "-"}
      </span>
    </div>
  );
};

export { SubscriptionsInActiveWeeklyRateCell };
