/* eslint-disable jsx-a11y/anchor-is-valid */
import { IVehicle } from '../../../../../app/modules/subscriptions/create/ISubscription'
import { useEffect, useState } from 'react'
import { getVehiclePackage } from '../../../../../app/modules/packages/packageVehicle/packageVehicle-list/core/_requests'
import { Packages } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
interface IMyProps {
  packages: Packages
  handleSelectPackage: (updatedPkg: object) => void
}

const Step2: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props

  const [searchedVehicles, setSearchedVehicles] = useState<any[]>([])
  const [selected, setSelected] = useState<number>()

  const searchVehicle = async (vName?: any) => {
    await getVehiclePackage(0, `search=${vName}`).then((result: any) => {
      setSearchedVehicles(result.data)
    })
  }

  /**
  * @param item
  * @returns Function for get weekly rate of vehicle to set in package its needed here...
  */
  const calculateVehicleWeeklyRate = (item: any) => {
    const plan = item?.plans.find((p: any) => p.package_category._id === newPackage?.packageId)
    if (plan) {
      return plan.weekly_rate
    } else {
      return 0
    }
  }

  const uniqueVehicles = searchedVehicles.filter((value, index, array) => index === array.findIndex(item => item.vehicle_name === value.vehicle_name));

  const handlePackageVehicle = (vehicle: any, iSelected: number) => {
    setSelected(iSelected)
    handleSelectPackage({ ...newPackage, vehicle: vehicle, weekly_rate: calculateVehicleWeeklyRate(vehicle) })
  }

  useEffect(() => {
    if (newPackage._id) {
      !searchedVehicles.length && searchVehicle(newPackage.vehicle.vehicle_name)
      let inx = searchedVehicles.findIndex(item => item.vehicle_name === newPackage.vehicle.vehicle_name)
      setSelected(inx)
    }
  }, [newPackage, searchedVehicles])


  return (
    <>
      {/*begin::Step 2 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-4'>
            <label className='required fs-5 fw-semibold mb-2'>Search By vehicle name</label>
            <input
              type='text'
              placeholder='Type to search a vehicle name'
              className='form-control form-control-lg form-control-solid'
              name='name'
              onChange={(e) => searchVehicle(e.target.value)}
            />
          </div>
          {/*end::Form Group */}

          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Select Vehicle From List</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Select vehicle'
              ></i>
            </label>

            <div className='py-5'>
              <div
                data-kt-search-element='suggestions'
                className={searchedVehicles && searchedVehicles.length > 0 ? 'd-none' : ''}
              >
                <div className='text-center px-4 pt-10'>
                  <img
                    src='/media/illustrations/sketchy-1/4.png'
                    alt=''
                    className='mw-100 mh-200px'
                  />
                </div>
              </div>

              <div
                data-kt-search-element='results'
                className={searchedVehicles && searchedVehicles.length > 0 ? '' : 'd-none'}
              >
                <div className='mh-300px scroll-y me-n5 pe-5'>
                  {uniqueVehicles
                    ? uniqueVehicles.map((vData, index) => (
                      <div
                        data-bs-dismiss='modal'
                        key={index}
                        className={`d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1 ${selected === index ? 'bg-secondary' : ''
                          }`}
                        data-kt-search-element='customer'
                        role='button'
                        onClick={() => handlePackageVehicle(vData, index)}
                      >

                        <div className='fw-semibold'>
                          <span className='fs-6 text-gray-800 me-2'>{vData?.vehicle_name}</span>
                        </div>
                        <div className='fw-semibold'>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>

              <div data-kt-search-element='empty' className='text-center d-none'>
                {/*begin::Message*/}
                <div className='fw-semibold py-0 mb-10'>
                  <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                  <div className='text-gray-400 fs-6'>
                    Try to search by username, full name or email...
                  </div>
                </div>
                {/*end::Message*/}
                {/*begin::Illustration*/}
                <div className='text-center px-4'>
                  <img
                    src='/media/illustrations/sketchy-1/9.png'
                    alt='user'
                    className='mw-100 mh-200px'
                  />
                </div>
                {/*end::Illustration*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Step 2 */}
    </>
  )
}

export { Step2 }
