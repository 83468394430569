import {ID, Response} from '../../../../../_metronic/helpers'
export type User = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type CreditWatch = {
  id?: ID
  subscription_id?: string
  hs_contact_id?: string
  xero_contact_id?: string
  cw_uid?: string
  processingStatus?: string
  contact_name?: string
  request_date?: string
  ref_number?: string
  processed_date?: string
  support_info?: {
    sales_representative_name?: string
    sales_representative_code?: string
    account_coordinator_name?: string
    account_coordinator_code?: string
  }
  ts_status?: string
  bond_amount?: number
  declined_remarks?: string
  decline_reasons?: any
  required_documents?: any
  status?: boolean
  avatar?: string
  position?: string
  role?: string
  updatedAt?: string
  documents?: any
}

export type CreditWatchDetails = {
  application: {
    uid: string
    referenceNumber: string
    processingStatus: string
    recommendedDecisions: object
    finalDecision: object
    formVersion: number
    submittedDate: string
    createdDate: string
    updatedDate: string
  }
}

export type UsersQueryResponse = Response<Array<User>>
export type SubscriptionsQueryResponse = Response<Array<CreditWatch>>
export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
}
export const initialSubscriptions: CreditWatch = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
}
