import React, {Dispatch, SetStateAction, ChangeEvent, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import {makeInvoicePayment} from '../../../../app/modules/invoices/invoice-list/core/_requests'
import {InvoiceDetailsLoading} from '../../../../app/modules/invoices/invoice-list/components/loading/InvoiceDetailsLoading'
type Props = {
  show: boolean
  handleClose: () => void
  invoiceData: any
  onDataFromChild: any
  // paymentRequest: PaymentRequest
}

interface ITxnReq {
  InvoiceID: any
  InvoiceNumber: any
  AmountDue: any
  ContactID: any
  Reference: any
  Name: any
  EmailAddress: any
  RentalId: any
  // Add other required properties here
}

const MakePayment: React.FC<Props> = ({show, handleClose, invoiceData, onDataFromChild}) => {
  const [amount, setAmount] = useState<string>('')
  const [customError, setCustomError] = useState<string | undefined>('')
  const [hasLoading, setHasLoading] = useState<boolean>(false)
  const dissmissLocation = () => {
    handleClose()
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const handleSubmit = () => {
    setHasLoading(true)
    // if (amount <= invoiceData.AmountDue && amount !== '') {
    if (amount !== '') {
      const paymentRequest: ITxnReq = {
        InvoiceID: invoiceData.InvoiceID,
        InvoiceNumber: invoiceData.InvoiceNumber,
        AmountDue: amount,
        ContactID: invoiceData.Contact.ContactID,
        Reference: invoiceData.Reference,
        Name: invoiceData.Contact.Name,
        EmailAddress: invoiceData.Contact.EmailAddress,
        RentalId: invoiceData?.Reference.split('-')[0],
        // Add other required properties here
      }
      console.log(paymentRequest)
      makeInvoicePayment(paymentRequest).then((response) => {
        setHasLoading(false)
        // console.log('hello response', response)
        if (response?.success === true) {
          setHasLoading(false)
          onDataFromChild(response)
        } else {
          setHasLoading(false)
          onDataFromChild(response)
          // setCustomError(response?.message)
        }
      })
    } else {
      setHasLoading(false)
      // setCustomError('Amount should not be blank or greater than the due amount!!')
      setCustomError('Amount should not be blank!!')
    }
  }

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg modal-dialog-centered max-width-380'
      aria-hidden='true'
      onHide={dissmissLocation}
    >
      <div className='modal-content'>
        {hasLoading && <InvoiceDetailsLoading />}
        <div className='modal-header'>
          <h5 className='modal-title'>Make Payment</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dissmissLocation}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>

        <div className='modal-body'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className=' mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>
                Pay full or partial amount of the invoice
              </div>
              <div className='text-dark-500 fw-semibold fs-4 mt-10'>
                Amount Due: {formatter.format(Number(invoiceData?.AmountDue))}
              </div>
            </div>
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Amount</label>
              <input
                placeholder='Please enter amount'
                type='number'
                name='amount'
                className='form-control'
                onChange={handleAmountChange}
                value={amount}
              />
              {customError && <div className='text-danger'>{customError}</div>}
            </div>
            {/* end::Form group */}
          </form>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dissmissLocation}>
            Cancel
          </button>
          {hasLoading ? (
            <button id='submit' type='button' className='btn btn-primary'>
              <span className=''>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          ) : (
            <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit}>
              <span className='indicator-label'>Pay Now</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export {MakePayment}
