// @ts-nocheck
import { Column } from 'react-table';
import { SubscriptionsInActiveActionsCell } from './SubscriptionsInActiveActionsCell';
import { SubscriptionsInActiveSelectionCell } from './SubscriptionsInActiveSelectionCell';
import { SubscriptionsInActiveCustomHeader } from './SubscriptionsInActiveCustomHeader';
import { SubscriptionsInActiveSelectionHeader } from './SubscriptionsInActiveSelectionHeader';
import { SubscriptionsInActiveOrgNameCell } from './SubscriptionsInActiveOrgNameCell';
import { SubscriptionsInActivePlanCell } from './SubscriptionsInActivePlanCell';
import { SubscriptionsInActiveLinkCell } from './SubscriptionsInActiveLinkCell';
import { CreditWatch } from '../../core/_models';
import { SubscriptionsInActiveContactNameCell } from './SubscriptionsInActiveContactNameCell';
import { SubscriptionsInActiveRentalIDCell } from './SubscriptionsInActiveRentalIDCell';
import { SubscriptionsInActiveStatusCell } from './SubscriptionsInActiveStatusCell';
import { SubscriptionsInActiveCreditCheckCell } from './SubscriptionsInActiveCreditCheckCell';
import { SubscriptionsInActiveDateCell } from './SubscriptionsInActiveDateCell';
import { SubscriptionsInActiveWeeklyRateCell } from './SubscriptionsInActiveWeeklyRateCell';
import { SubscriptionsInActivePackageCell } from './SubscriptionsInActivePackageCell';

const SubscriptionsColumns: ReadonlyArray<Column<CreditWatch>> = [
  {
    Header: (props) => <SubscriptionsInActiveSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => (
      <SubscriptionsInActiveSelectionCell id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsInActiveCustomHeader tableProps={props} title='Rental Id' className='min-w-125px' />
      )
    },
    id: 'subscription_id',
    Cell: ({ ...props }) => {
      return <SubscriptionsInActiveRentalIDCell values={props.data[props.row.index]?.subscription_id} />
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsInActiveCustomHeader
          tableProps={props}
          title='Customer Name'
          className='min-w-125px'
        />
      );
    },
    id: 'firstname',
    Cell: ({ ...props }) => {
      return (
        <SubscriptionsInActiveContactNameCell
          values={props.data[props.row.index]}
        />
      );
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsInActiveCustomHeader
          tableProps={props}
          title='Organization Name'
          className='min-w-125px'
        />
      );
    },
    id: 'organization.organization_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsInActiveOrgNameCell values={props.data[props.row.index]} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsInActiveCustomHeader
        tableProps={props}
        title='Deal Owner'
        className='min-w-125px'
      />
    ),
    id: 'advance_information.sales_representative_name',
    Cell: ({ ...props }) => (
      <SubscriptionsInActivePlanCell
        values={props.data[props.row.index]?.advance_information?.sales_representative_name === "undefined undefined" ? '-' : props.data[props.row.index]?.advance_information?.sales_representative_name}
      />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsInActiveCustomHeader
          tableProps={props}
          title='Package'
          className='min-w-125px'
        />
      );
    },
    id: 'packages[0].package_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsInActivePackageCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsInActiveCustomHeader
          tableProps={props}
          title='Weekly Rate'
          className='min-w-125px'
        />
      );
    },
    id: 'packages[0].weekly_rate',
    Cell: ({ ...props }) => {
      return <SubscriptionsInActiveWeeklyRateCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsInActiveCustomHeader
        tableProps={props}
        title='Delivery Start Date'
        className='min-w-125px'
      />
    ),
    id: 'packages[0].start_date',
    Cell: ({ ...props }) => (
      <SubscriptionsInActiveDateCell values={props.data[props.row.index].packages[0] ?? '-'} />
    ),
  },
  {
    Header: (props) => (
      <SubscriptionsInActiveCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-120px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <SubscriptionsInActiveActionsCell
        id={props.data[props.row.index].subscription_id}
      />
    ),
  },
];

export { SubscriptionsColumns };
