import {useListView} from '../../core/ListViewProvider'
import { SubscriptionsMasterSearchComponent } from './SubscriptionsMasterSearchComponent'

const SubscriptionsMasterSearchHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionsMasterSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? (
          <SubsPendingApplicationGrouping />
        ) : (
          <SubsPendingApplicationToolbar />
        )} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SubscriptionsMasterSearchHeader}
