/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { deleteVehiclePackages, getVehiclePackage } from '../packageVehicle/packageVehicle-list/core/_requests'
import { VehiclePackageModal } from './components/VehiclePackageModal'
import { PackageVehicle } from '../packageVehicle/packageVehicle-list/core/_models'
import DeleteConfirmationModal from '../categories/category-list/components/deleteConfirmationModal/ConfirmationModal'
import ExcelJS from 'exceljs';
import { VehiclePackagePagination } from './components/VehiclePackagePagination'
import { getCategories } from '../categories/category-list/core/_requests'

const VehiclePackages: React.FC = () => {

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isEdit, setIsEdit] = useState<any>("")
    const [vehicleData, setVehicleData] = useState<PackageVehicle[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [pagination, setPagination] = useState<{ page: number; totalPages: number }>({ page: 1, totalPages: 0 });
    const [sortConfig, setSortConfig] = useState({ sort: 'vehicle_name', order: '' });
    const [packageCategory, setPackageCategory] = useState<PackageVehicle[]>([])

    useEffect(() => {
        getCategories("").then((res: any) => {
            setPackageCategory(res?.data?.docs);
        });
        fetchData(pagination.page)
    }, [sortConfig, searchKey, pagination.page])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(event.target.value);
    };

    const fetchData = async (page: number = 1) => {
        const { sort, order } = sortConfig;
        await getVehiclePackage(page, `search=${searchKey}&sort=${sort}&order=${order}`).then((res: any) => {
            setVehicleData(res.data.docs)
            setPagination({ page: res.data.page, totalPages: res.data.totalPages });
        })
    }

    const handleSort = (column: string) => {
        setSortConfig((prev) => ({
            sort: column,
            order: prev.sort === column && prev.order === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleDeleteConfirmation = async () => {
        deleteVehiclePackages(isEdit);
        fetchData();
        setShowDeleteModal(false);
    };

    const handlePageChange = (page: number) => {
        setPagination((prev) => ({ ...prev, page }));
    };

    const handleExport = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Vehicle Packages');

        // Define styles
        const headerStyle = {
            font: { bold: true, color: { argb: 'FFFFFF' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '4F81BD' } },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };

        const subHeaderStyle = {
            font: { bold: true, color: { argb: '000000' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9EAD3' } },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };

        const cellStyle = {
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };

        // Define headers and data
        const headers = [
            ['Vehicle Title', 'Short Term Direct', '', '', 'Accident Replacement', '', '', 'FlexiRent', '', '', 'Rent To Own', '', ''],
            ['', 'Daily', 'Weekly', 'Monthly', 'Daily', 'Weekly', 'Monthly', 'Daily', 'Weekly', 'Monthly', 'Daily', 'Weekly', 'Monthly']
        ];

        const data = vehicleData.map((vehicle) => {
            const getPlanRates = (category: string) => {
                const plan = vehicle.plans.find((p: any) => p.package_category.name === category);
                return {
                    daily_rate: plan?.daily_rate ?? '-',
                    weekly_rate: plan?.weekly_rate ?? '-',
                    monthly_rate: plan?.monthly_rate ?? '-'
                };
            };

            const shortTermDirect = getPlanRates('Short Term Direct');
            const accidentReplacement = getPlanRates('Accident Replacement');
            const flexiRent = getPlanRates('FlexiRent');
            const rentToOwn = getPlanRates('Rent To Own');

            return [
                vehicle.vehicle_name,
                shortTermDirect.daily_rate,
                shortTermDirect.weekly_rate,
                shortTermDirect.monthly_rate,
                accidentReplacement.daily_rate,
                accidentReplacement.weekly_rate,
                accidentReplacement.monthly_rate,
                flexiRent.daily_rate,
                flexiRent.weekly_rate,
                flexiRent.monthly_rate,
                rentToOwn.daily_rate,
                rentToOwn.weekly_rate,
                rentToOwn.monthly_rate
            ];
        });

        // Add headers to the worksheet
        const headerRow = worksheet.addRow(headers[0]);
        headerRow.eachCell({ includeEmpty: true }, (cell: any) => {
            cell.style = headerStyle;
        });

        // Merge cells for category headers
        worksheet.mergeCells('B1:D1');
        worksheet.mergeCells('E1:G1');
        worksheet.mergeCells('H1:J1');
        worksheet.mergeCells('K1:M1');

        // Add sub-header
        const subHeaderRow = worksheet.addRow(headers[1]);
        subHeaderRow.eachCell({ includeEmpty: true }, (cell: any) => {
            cell.style = subHeaderStyle;
        });

        // Add data rows
        data.forEach(rowData => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell: any) => {
                cell.style = cellStyle;
            });
        });

        // Auto adjust column widths
        worksheet.columns.forEach((column: any) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell: any) => {
                const length = cell.value ? cell.value.toString().length : 0;
                if (length > maxLength) maxLength = length;
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        // Save the workbook to a file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'vehicle-packages.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        });
    };

    return (
        <>
            <div className='card'>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                value={searchKey}
                                onChange={handleSearch}
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search Vehicle Package'
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                            {/* begin::Export */}
                            <button type='button' className='btn btn-light-primary me-3' onClick={handleExport}>
                                <KTIcon iconName='exit-up' className='fs-2' />
                                Export
                            </button>
                            {/* end::Export */}

                            {/* begin::Add user */}
                            <button type='button' className='btn btn-primary' onClick={() => setIsOpenModal(true)}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Vehicle Packages
                            </button>
                            {/* end::Add user */}
                        </div>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="table-responsive">
                            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_pkg">
                                <thead>
                                    <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                        <th className="w-10px pe-2">
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_pkg .form-check-input" />
                                            </div>
                                        </th>
                                        {/* <th className='min-w-125px fixed-column table-sort-desc cursor-pointer'>Vehicle Title</th> */}
                                        <th
                                            className={`cursor-pointer ${sortConfig.sort === 'vehicle_name' ? `table-sort-${sortConfig.order}` : ''}`}
                                            onClick={() => handleSort('vehicle_name')}
                                        >
                                            Vehicle Name
                                        </th>
                                        {packageCategory.map((item: any) => (
                                            <th colSpan={3} className='min-w-125px'>{item.name}</th>
                                        ))}
                                        {/* <th colSpan={3} className='min-w-125px'>Short Term Direct</th>
                                        <th colSpan={3} className='min-w-125px'>Accident Replacement</th>
                                        <th colSpan={3} className='min-w-125px'>FlexiRent</th>
                                        <th colSpan={3} className='min-w-125px'>Rent To Own</th> */}
                                        <th className='text-end min-w-100px'>Actions</th>
                                    </tr>
                                    <tr className='text-center text-gray-600 text-muted fw-bold fs-7 text-uppercase gs-0'>
                                        <th className="w-10px pe-2">
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                {/* <input className="form-check-input" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_pkg .form-check-input" /> */}
                                            </div>
                                        </th>
                                        <th></th>
                                        {packageCategory.flatMap(() => (
                                            <>
                                                <th>Daily</th>
                                                <th>Weekly</th>
                                                <th>Monthly</th>
                                            </>
                                        ))}
                                        {/* <th>Daily</th>
                                        <th>Weekly</th>
                                        <th>Monthly</th>
                                        <th>Daily</th>
                                        <th>Weekly</th>
                                        <th>Monthly</th>
                                        <th>Daily</th>
                                        <th>Weekly</th>
                                        <th>Monthly</th>
                                        <th>Daily</th>
                                        <th>Weekly</th>
                                        <th>Monthly</th> */}
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-bold text-center">
                                    {vehicleData.map((vehicle, index) => (
                                        <tr key={vehicle._id} role='row'>
                                            <td role="cell" className="fixed-column">
                                                <div className="form-check form-check-custom form-check-solid">
                                                    <input className="form-check-input" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_pkg .form-check-input" />
                                                </div>
                                            </td>
                                            <td role="cell" className="fixed-column">
                                                <div className="d-flex text-center">
                                                    <div className="d-flex flex-column text-nowrap text-center">
                                                        <a href="#" className="text-gray-800 text-hover-primary mb-1">{vehicle.vehicle_name}</a>
                                                    </div>
                                                </div>
                                            </td>
                                            {packageCategory.map((category: any) => {
                                                const plan = vehicle.plans.find((p: any) => p.package_category?.name === category?.name)
                                                return (
                                                    <>
                                                        <td className='min-w-100px'>{plan?.daily_rate ?? '-'}</td>
                                                        <td className='min-w-100px'>{plan?.weekly_rate ?? '-'}</td>
                                                        <td className='min-w-100px'>{plan?.monthly_rate ?? '-'}</td>
                                                    </>
                                                )
                                            })}
                                            <td role="cell" className="text-end min-w-100px">
                                                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    Actions <i className="ki-duotone ki-down fs-5 m-0"></i>
                                                </a>
                                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <div className="menu-item px-3" onClick={() => { setIsOpenModal(true); setIsEdit(vehicle._id) }}>
                                                        <a className="menu-link px-3">Edit</a>
                                                    </div>
                                                    <div className="menu-item px-3" onClick={() => { setShowDeleteModal(true); setIsEdit(vehicle._id) }}>
                                                        <a className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {vehicleData?.length > 0 && (
                    <VehiclePackagePagination pagination={pagination} onPageChange={handlePageChange} />
                )}
            </div>
            {isOpenModal && <VehiclePackageModal closeModal={() => { setIsOpenModal(false); setIsEdit("") }} fetchData={() => fetchData()} isEdit={isEdit} />}
            <DeleteConfirmationModal
                show={showDeleteModal}
                onClose={() => { setShowDeleteModal(false); setIsEdit("") }}
                onConfirm={handleDeleteConfirmation}
            />
        </>
    )
}

export default VehiclePackages
