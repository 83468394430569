// @ts-nocheck
import { Column } from 'react-table';
import { SubscriptionsMasterSearchActionsCell } from './SubscriptionsMasterSearchActionsCell';
import { SubscriptionsMasterSearchSelectionCell } from './SubscriptionsMasterSearchSelectionCell';
import { SubscriptionsMasterSearchCustomHeader } from './SubscriptionsMasterSearchCustomHeader';
import { SubscriptionsMasterSearchSelectionHeader } from './SubscriptionsMasterSearchSelectionHeader';
import { SubscriptionsMasterSearchOrgNameCell } from './SubscriptionsMasterSearchOrgNameCell';
import { SubscriptionsMasterSearchPlanCell } from './SubscriptionsMasterSearchPlanCell';
import { SubscriptionsMasterSearchLinkCell } from './SubscriptionsMasterSearchLinkCell';
import { CreditWatch } from '../../core/_models';
import { SubscriptionsMasterSearchContactNameCell } from './SubscriptionsMasterSearchContactNameCell';
import { SubscriptionsMasterSearchRentalIDCell } from './SubscriptionsMasterSearchRentalIDCell';
import { SubscriptionsMasterSearchStatusCell } from './SubscriptionsMasterSearchStatusCell';
import { SubscriptionsMasterSearchCreditCheckCell } from './SubscriptionsMasterSearchCreditCheckCell';
import { SubscriptionsMasterSearchDateCell } from './SubscriptionsMasterSearchDateCell';
import { SubscriptionsMasterSearchWeeklyRateCell } from './SubscriptionsMasterSearchWeeklyRateCell';
import { SubscriptionsMasterSearchPackageCell } from './SubscriptionsMasterSearchPackageCell';

const SubscriptionsColumns: ReadonlyArray<Column<CreditWatch>> = [
  {
    Header: (props) => <SubscriptionsMasterSearchSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => (
      <SubscriptionsMasterSearchSelectionCell id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsMasterSearchCustomHeader tableProps={props} title='Rental Id' className='min-w-125px' />
      )
    },
    id: 'subscription_id',
    Cell: ({ ...props }) => {
      return <SubscriptionsMasterSearchRentalIDCell values={props.data[props.row.index]?.subscription_id} />
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsMasterSearchCustomHeader
          tableProps={props}
          title='Customer Name'
          className='min-w-125px'
        />
      );
    },
    id: 'firstname',
    Cell: ({ ...props }) => {
      return (
        <SubscriptionsMasterSearchContactNameCell
          values={props.data[props.row.index]}
        />
      );
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsMasterSearchCustomHeader
          tableProps={props}
          title='Organization Name'
          className='min-w-125px'
        />
      );
    },
    id: 'organization.organization_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsMasterSearchOrgNameCell values={props.data[props.row.index]} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsMasterSearchCustomHeader
        tableProps={props}
        title='Deal Owner'
        className='min-w-125px'
      />
    ),
    id: 'advance_information.sales_representative_name',
    Cell: ({ ...props }) => (
      <SubscriptionsMasterSearchPlanCell
        values={props.data[props.row.index]?.advance_information?.sales_representative_name === "undefined undefined" ? '-' : props.data[props.row.index]?.advance_information?.sales_representative_name}
      />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsMasterSearchCustomHeader
          tableProps={props}
          title='Package'
          className='min-w-125px'
        />
      );
    },
    id: 'packages[0].package_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsMasterSearchPackageCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsMasterSearchCustomHeader
          tableProps={props}
          title='Weekly Rate'
          className='min-w-125px'
        />
      );
    },
    id: 'packages[0].weekly_rate',
    Cell: ({ ...props }) => {
      return <SubscriptionsMasterSearchWeeklyRateCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsMasterSearchCustomHeader
        tableProps={props}
        title='Delivery Start Date'
        className='min-w-125px'
      />
    ),
    id: 'packages[0].start_date',
    Cell: ({ ...props }) => (
      <SubscriptionsMasterSearchDateCell values={props.data[props.row.index].packages[0] ?? '-'} />
    ),
  },
  {
    Header: (props) => (
      <SubscriptionsMasterSearchCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-120px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <SubscriptionsMasterSearchActionsCell
        data={props.data[props.row.index]}
      />
    ),
  },
];

export { SubscriptionsColumns };
