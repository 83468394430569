import axios, { AxiosResponse } from 'axios'
import { SubscriptionsQueryResponse } from './_models'

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const GET_IN_ACTIVE_URL = `${BACKEND_API_URL}/subscriptions/get-inActive-subscriptions`


const getInActiveSubscriptionsList = (query: string): Promise<SubscriptionsQueryResponse> => {
  return axios
    .get(`${GET_IN_ACTIVE_URL}?${query}`)
    .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

export { getInActiveSubscriptionsList }
