import axios, { AxiosResponse } from 'axios'
import { SubscriptionsQueryResponse } from './_models'

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const GET_ALL_SUBS_URL = `${BACKEND_API_URL}/subscriptions/get-all-subscriptions`


const getMasterSearchSubscriptionsList = (query: string): Promise<SubscriptionsQueryResponse> => {
  return axios
    .get(`${GET_ALL_SUBS_URL}?${query}`)
    .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

export { getMasterSearchSubscriptionsList }
