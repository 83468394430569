import {useState, useEffect} from 'react'
import {Packages} from '../../../../../app/modules/subscriptions/context/SubscriptionContext'

interface IMyProps {
  packages: Packages
  handleSelectPackage: (updatedPkg: object) => void
}

const Step5: React.FC<IMyProps> = (props: IMyProps) => {
  const {packages: newPackage, handleSelectPackage} = props
  const [selectedSignage, setSelectedSignage] = useState<string>('')

  let {
    vehicle: {vehicle_name},
  } = newPackage
  const vehicleName = vehicle_name

  const isDualCab = vehicleName?.toLowerCase().includes('dual')

  const signageOptions = isDualCab
    ? [
        {id: 'option2', label: 'Signage Option 2 Dual Cab (no tray)'},
        {id: 'option4', label: 'Signage Option 4 Dual Cab (with Tray)'},
        {id: 'option5', label: 'Signage Option 5 Dual Cab (tub)'},
      ]
    : [
        {id: 'option1', label: 'Signage Option 1 Single Cab (no tray)'},
        {id: 'option3', label: 'Signage Option 3 Single Cab (with Tray)'},
      ]

  useEffect(() => {
    if (newPackage?.signageOption) {
      setSelectedSignage(newPackage.signageOption)
    }
  }, [newPackage])

  const handleSignageSelect = (option: string) => {
    const newSelection = selectedSignage === option ? '' : option
    setSelectedSignage(newSelection)
    handleSelectPackage({...newPackage, signageOption: newSelection || null})
  }

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row'>
          <label className='fs-6 text-dark mb-7 d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className=''>Select Signage Option</span>
          </label>

          <div className='row g-3'>
            {signageOptions.map((option, index) => (
              <div key={index} className='col-12'>
                <div
                  className={`card p-3 pt-4 cursor-pointer d-flex flex-row align-items-center justify-content-between ${
                    selectedSignage === option.label
                      ? 'border-primary bg-light-primary'
                      : 'border-light'
                  }`}
                  onClick={() => handleSignageSelect(option.label)}
                  style={{
                    borderWidth: '2px',
                    transition: 'all 0.2s ease',
                    boxShadow:
                      selectedSignage === option.label
                        ? '0 4px 12px rgba(0, 123, 255, 0.2)'
                        : 'none',
                  }}
                >
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-30px me-4'>
                      <div
                        className={`symbol-label ${
                          selectedSignage === option.label ? 'bg-primary' : 'bg-light'
                        }`}
                      >
                        <i className='fas fa-truck text-white fs-1x'></i>
                      </div>
                    </div>
                    <div>
                      <h5 className='fw-bold mb-0'>{option.label}</h5>
                    </div>
                  </div>
                  {selectedSignage === option.label && (
                    <div>
                      <i className='fas fa-check-circle text-primary fs-3'></i>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step5}
