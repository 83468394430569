/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useLayoutEffect } from 'react';
import { toAbsoluteUrl } from '../../../../helpers';
import { PackageContext } from '../../../../../app/modules/subscriptions/context/SubscriptionContext';
import { formatDuration, NumberToWord } from '../../../../helpers/UtilityHelpers';
import moment from 'moment';

interface IMyProps {
    packages: Pkg
}

interface Pkg {
    packageId: string;
    packageType: string;
    planType: any;
    planCost: string;
    vehicleType: string;
    flexiRentQty: number;
    contract_days: number;
    min_contract_period: number;
    total_KMs: string;
    contracted_services: string;
    tyres: number;
    weekly_rate: string;
    vehicle: {
        body_type: string;
        color: string;
        fuel_type: string;
        Make: string;
        Model: string;
        vehicle_name: string;
        registration: string;
        transmission: string;
        vin: string;
        year: string;
        _id: string;
    };
    accessories: Array<any>,
    signageOption: string,
    weekly_discount: number,
    deliveryDetails: {
        deliveryDateTime: string,
        delivery_Location: string,
        country: string,
        district_city: string,
        address_line_1: string,
        address_line_2: string,
        state_province: string,
        postal_code: string,
    },
    start_date: string,
    end_date: string,
    approve: boolean,
    completed: boolean;
};

const Step7: React.FC<IMyProps> = (props: IMyProps) => {

    const { packages: newPackage } = props

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            console.log('This will run after 1 second!');
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div data-kt-stepper-element="content">
                <div className="w-100">
                    {/* Heading */}
                    <h1 className="fw-bold text-dark mb-3 text-center">Acknowledge!</h1>

                    {/* Description */}
                    <div className="text-muted fw-semibold fs-4 text-center">
                        Acknowledge package information before submitting.
                    </div>

                    {/* Package Details */}
                    <div className="mt-6 px-12">
                        {/* Package Type */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Package Name</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.packageType}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Plan Type */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Plan Type</div>
                            <div className="fw-bold text-gray-800 ms-8">
                                {newPackage?.contract_days > 0 && formatDuration(newPackage?.contract_days)}
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* total_KMs */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Total KMs</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.total_KMs}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* contract Services */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Contracted Service</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.contracted_services}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* tyres */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Tyres</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.tyres}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Vehicle Name */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Vehicle</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.vehicle.vehicle_name}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Accessories */}
                        <div className='d-flex fv-row'>
                            <div className='col-12'>
                                <h5 className='fw-bold text-gray-600 min-w-100px'>Accessories</h5>
                                <ul className='list-group'>
                                    {newPackage?.accessories?.map((accessory) => (
                                        <li key={accessory._id} className='list-group-item d-flex justify-content-between align-items-center'>
                                            {accessory.name}
                                            <span className='badge bg-primary rounded-pill'>${accessory.price}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Signage Option */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Signage Option</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.signageOption}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Weekly rate */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Weekly Rate</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.weekly_rate}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Weekly Discount */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Weekly Discount</div>
                            <div className="fw-bold text-gray-800 ms-8">{newPackage?.weekly_discount}</div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        {/* Delivery Details */}
                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Delivery Date/Time</div>
                            <div className="fw-bold text-gray-800 ms-8">
                                {newPackage?.deliveryDetails?.deliveryDateTime ?
                                    moment(newPackage?.deliveryDetails?.deliveryDateTime).format('MMMM Do, YYYY')
                                    : 'Not specified'}
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>

                        <div className="d-flex fv-row">
                            <div className="fw-semibold text-gray-600 min-w-100px">Delivery Address</div>
                            <div className="fw-bold text-gray-800 ms-8">
                                <div>{newPackage?.deliveryDetails?.address_line_1 || ''}</div>
                                <div>{newPackage?.deliveryDetails?.district_city || ''}</div>
                                <div>{newPackage?.deliveryDetails?.state_province || ''}</div>
                                <div>{newPackage?.deliveryDetails?.postal_code || ''}</div>
                                <div>{newPackage?.deliveryDetails?.country || ''}</div>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { Step7 };
